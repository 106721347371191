const LOG_PREFIX = '[OCM][AniView] '

module.exports = class AniView {
    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.aniview_config = (this.utils.is_mobile) ? config.services.aniview.mobile : config.services.aniview.desktop
        this.aniview_config['debug'] = this.config.services.aniview.debug
        this.ADSQ_TARGETING = null;
        this.ANIVIEW_FEE = 0.0002;
        this.isHeaderBidding = false;
        if (typeof this.aniview_config.vchack === 'undefined') {
            this.aniview_config.vchack = true
        }
    }

    run() {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }

        if (this.utils.allowPageType(this.aniview_config.rules.page_types)) {
            this.createPlayerElement()
        } else if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + "Page type doesn't match the selected ones: ", this.aniview_config.rules.page_types)
        }
    }

    createPlayerElement() {
        this.utils.contentLoaded().then(() => {
            this.inject()
        })
    }

    inject() {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'Trying to inject player element')
        }

        let node = this.utils.determineInjectionTarget(this.aniview_config.rules.selector, this.aniview_config.rules.position, this.aniview_config.rules.count_gt, this.aniview_config.rules.word_count, this.aniview_config.rules.words_gt)

        if (this.config.debug || this.aniview_config.debug) {
            console.info(LOG_PREFIX + 'node found: ', node)
        }

        if (!node) {
            if (this.config.debug || this.aniview_config.debug) {
                console.info(LOG_PREFIX + 'node NOT found, stopping process', node)
            }
            return;
        }

        let tagScript = document.createElement('script')
        tagScript.id = `AV${this.aniview_config.playerTagId}`
        tagScript.type = "text/javascript"
        tagScript.src = `https://tg1.aniview.com/api/adserver/spt?AV_PUBLISHERID=${this.aniview_config.publisher}&AV_TAGID=${this.aniview_config.playerTagId}`
        if (this.utils.window.OCM._oeid) {
            tagScript.src += `&AV_CUSTOM5=${this.utils.window.OCM._oeid}`
        }
        tagScript.async = "true"
        tagScript.setAttribute('data-config-api', 'avConfig')
        tagScript.setAttribute('data-player-api', 'avPassback')

        // Append sticky style to aniview player container
        if (this.aniview_config && this.aniview_config.sticky && this.aniview_config.sticky.enable) {
            if (this.config.debug || this.aniview_config.debug) {
                console.info(LOG_PREFIX + ' sticky option is enabled for aniview player');
            }
            this.appendStickStyle();
        }

        let ocmPlayer = this.utils.window.document.createElement('div')
        ocmPlayer.classList.add('ocm-player')
        if (this.aniview_config.classes) {
            ocmPlayer.classList.add(this.aniview_config.classes)
        }
        if (this.aniview_config.styles) {
            ocmPlayer.style = this.aniview_config.styles
        }

        // Check for existence of chirp.js or hb adsqRTDModuleEnabled. If any of those are true, wait for window.ADSQ
        if (window.top.document.querySelector('script[src*="chirp.js"]') || this.utils.config.services.header_bidding.adsqRTDModuleEnabled) {
            this.utils.waitFor('ADSQ.response', () => {
                let classifiedAs = []
                let response = null
                response = window.top.ADSQ?.response
                if (response) {
                    if (response.classified_as) {
                        response.classified_as.forEach((classification) => {
                            let split = []
                            split = classification.split('/')
                            let tmp = null
                            split.forEach((term) => {
                                if (!tmp) {
                                    tmp = term
                                } else {
                                    tmp += '/' + term
                                }

                                classifiedAs.push(tmp)
                            })
                        })
                    }

                    this.ADSQ_TARGETING = {
                        adsq_bs: (response.brandSafe) ? (response.brandSafe === true) ? 'Yes' : 'No' : 'notEvaluated',
                        adsq_bs_class: (classifiedAs.length) ? [...new Set(classifiedAs)] : [],
                        adsq_bs_ne: response.entities || [],
                        adsq_bs_sent: response.sentiment || '',
                        hostname: window.top.location.hostname.replace('www.', ''),
                        city: response?.geo?.city || '',
                        country: response?.geo?.country || '',
                    }

                    tagScript.src += `&AV_CUSTOM1=${this.ADSQ_TARGETING.adsq_bs}`
                    tagScript.src += (this.ADSQ_TARGETING.adsq_bs_class.length) ? `&AV_CUSTOM2=${encodeURIComponent(this.ADSQ_TARGETING.adsq_bs_class.join(','))}` : ''
                    tagScript.src += (this.ADSQ_TARGETING.adsq_bs_sent !== '') ? `&AV_CUSTOM3=${this.ADSQ_TARGETING.adsq_bs_sent}` : ''
                    tagScript.src += (this.ADSQ_TARGETING.adsq_bs_ne.length) ? `&AV_CUSTOM4=${encodeURIComponent(this.ADSQ_TARGETING.adsq_bs_ne.join(','))}` : ''

                    // Smart ad server targeting [AV_CUSTOM7]
                    let av_custom7 = 'adsq_bs=' + this.ADSQ_TARGETING.adsq_bs + ';'
                    if ((this.ADSQ_TARGETING.adsq_bs_class.length)) {
                        this.ADSQ_TARGETING.adsq_bs_class.forEach((classification) => {
                            av_custom7 += 'adsq_bs_class=' + encodeURIComponent(classification) + ';'
                        })
                    }
                    av_custom7 += (this.ADSQ_TARGETING.adsq_bs_sent !== '') ? 'adsq_bs_sent=' + this.ADSQ_TARGETING.adsq_bs_sent + ';' : ''
                    if ((this.ADSQ_TARGETING.adsq_bs_ne.length)) {
                        this.ADSQ_TARGETING.adsq_bs_ne.forEach((entity) => {
                            av_custom7 += 'adsq_bs_ne=' + encodeURIComponent(entity) + ';'
                        })
                    }

                    tagScript.src += `&AV_CUSTOM7=${encodeURIComponent(av_custom7)}`
                }

                this.appendTag(node, ocmPlayer, tagScript)
            }, 50, () => {
                this.appendTag(node, ocmPlayer, tagScript)
            })
        } else {
            this.appendTag(node, ocmPlayer, tagScript)
        }
    }

    appendStickStyle() {
        const top = `${this.aniview_config?.sticky?.offsets?.top || 0}px`;
        const bottom = `${this.aniview_config?.sticky?.offsets?.bottom || 0}px`;
        const left = `${this.aniview_config?.sticky?.offsets?.left || 0} px`;
        const right = `${this.aniview_config?.sticky?.offsets?.right || 0}px`;
        const height = `${this.aniview_config?.sticky?.height || 0}px`
        const width = `${this.aniview_config?.sticky?.width || 0}px`

        switch (this.aniview_config?.sticky?.position) {
            case 'bottom_right':
                this.utils.loadStyle(`.ocm-player { position: fixed; z-index: 2147483646; bottom: ${bottom}; right: ${right};  height: ${height}; width: ${width}; }`);
                break;
            case 'bottom_left':
                this.utils.loadStyle(`.ocm-player { position: fixed; z-index: 2147483646; bottom: ${bottom}; left: ${left};  height: ${height}; width: ${width}; }`);
                break;
            case 'top_right':
                this.utils.loadStyle(`.ocm-player { position: fixed; z-index: 2147483646; top: ${top}; right: ${right};  height: ${height}; width: ${width}; }`);
                break;
            case 'top_left':
                this.utils.loadStyle(`.ocm-player { position: fixed; z-index: 2147483646; top: ${top}; left: ${left};  height: ${height}; width: ${width}; }`);
                break;
        }
    }

    appendTag(node, ocmPlayer, tagScript) {
        if (!this.utils.window.googletag.pubads) {
            this.utils.injectTag(node, ocmPlayer, this.aniview_config.rules.place)
            ocmPlayer.append(tagScript)

            if (this.config.debug || this.aniview_config.debug) {
                console.log(LOG_PREFIX + 'Selector found and script added')
            }
        } else {
            this.utils.gptPubAdsReady().then(() => {
                this.utils.window.googletag.cmd.push(() => {
                    const GamPageLevelTargeting = this.utils.window.googletag.pubads().getTargetingKeys().map((key) => {
                        return encodeURIComponent(`${key}=${this.utils.window.googletag.pubads().getTargeting(key).join(',')}`)
                    }).join('%26')

                    tagScript.src += `&AV_CUSTOM6=${GamPageLevelTargeting}`

                    this.utils.injectTag(node, ocmPlayer, this.aniview_config.rules.place)
                    ocmPlayer.append(tagScript)

                    if (this.config.debug || this.aniview_config.debug) {
                        console.log(LOG_PREFIX + 'Selector found and script added')
                    }

                    this.createPassbacks()
                })
            })
        }
    }

    resizePlayerForVerticalAd(event, player) {
        try {
            if (this.config.debug || this.aniview_config.debug) {
                console.log(LOG_PREFIX + 'AdImpression Event: ', event);
            }

            const width = event?.tagInfo?.mediaInfo?.width;
            const playerWidth = player.getWidth();
            const height = event?.tagInfo?.mediaInfo?.height;

            if (height <= width) {
                return;
            }

            if (this.config.debug || this.aniview_config.debug) {
                console.log(LOG_PREFIX + 'Tag width: ', width, ' Tag height: ', height);
            }

            const ratio = width / height;
            const playerHeight = playerWidth / ratio;
            player.resize(playerWidth, playerHeight);
        } catch (e) {
            if (this.config.debug || this.aniview_config.debug) {
                console.error(LOG_PREFIX + 'Error: ', e);
            }
        }
    }

    handleAdImpression(event) {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'An new impression event was triggered:', event)
        }
        const tagInfo = {...event.tagInfo};
        if (tagInfo) {
            const size = tagInfo.bidder && this.auction && this.auction.bidders && this.auction.bidders[tagInfo.bidder].size;
            const conversion = this.utils.window && this.utils.window.OCM && this.utils.window.OCM.getCurrencyConversion && this.utils.window.OCM.getCurrencyConversion('USD');
            let cpm = tagInfo.bidCpm;
            if(cpm > 0){
                cpm = conversion ? parseFloat(((cpm - this.ANIVIEW_FEE) * conversion).toFixed(5)) : cpm - this.ANIVIEW_FEE;
            }
            this.isHeaderBidding = tagInfo.bidder && tagInfo.bidder !== 'GAM' && tagInfo.bidder !== 'amazon';
            if (!this.isHeaderBidding) {
                return;
            }

            const eventData = {
                data: {
                    adUnit: 'aniview_outstream',
                    auction: this.auction,
                    auctionId: this.auctionId,
                    bidder: tagInfo.bidder,
                    consent: this.utils.window.OCM.hasPurposeOneConsent,
                    cpm: cpm,
                    dealId: tagInfo.bidData.dealId || null,
                    fizz: false,
                    isNew: 1,
                    mediaType: "video",
                    meta: JSON.stringify(tagInfo.bidData.meta),
                    oeid: this.utils.window.OCM._oeid,
                    size: size,
                    source: "client"
                },
                event: "pba-update",
                eventName: "Bid won",
                options: this.options
            }

            // Send the aniview analytics data to wind runner
            fetch('https://windrunner.orangeclickmedia.com/pba-update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain'
                },
                body: JSON.stringify(eventData)
            }).then((response) => {
                response.json().then(() => {
                    if (this.config.debug || this.aniview_config.debug) {
                        console.log(LOG_PREFIX + 'A pba update was sent to wind runner successfully with data: ', eventData);
                    }
                })
            }).catch((err) => {
                console.error(err)
            })
        }
    }

    handleAnalyticsCallback(event) {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'An new analytics callback event was triggered:', event);
        }
        const data = event.data;
        if(data){
            // Get the options and remove deprecated fields
            const options = Object.assign({},event.options);
            delete options.callback;
            options.options = {};
            this.options = options; // store them from pba update

            // Get all bidders from each ad unit and merge them into a single object - Good luck :)
            const bidders = Object.keys(data.adUnits)
                .map(k => Object.keys(data.adUnits[k].bidders).map(b => data.adUnits[k].bidders[b]))
                .flat()
                .reduce((a, v) => ({...a, [v.bidder]: v}), {});
            this.bidders = bidders; // store them from pba update

            // Convert cpm to AdServer currency
            Object.keys(this.bidders).forEach(bidder => {
                const conversion = this.utils.window && this.utils.window.OCM && this.utils.window.OCM.getCurrencyConversion && this.utils.window.OCM.getCurrencyConversion('USD');
                let cpm = this.bidders[bidder].cpm;
                if(cpm > 0){
                    cpm = conversion ? parseFloat(((cpm - this.ANIVIEW_FEE) * conversion).toFixed(5)) : cpm - this.ANIVIEW_FEE;
                }
                this.bidders[bidder].cpm = cpm;
            });

            // Create the ad units object with proper format
            this.auction = {
                adUnit: 'aniview_outstream',
                bidders: bidders,
                finish: data.finish,
                start: data.start,
                status: "finished",
                timeout: data.timeout
            };

            this.auctionId = data.id;
            const eventData= {
                data: {
                    id: this.auctionId,
                    start: data.start,
                    timeout: data.timeout,
                    adUnits: {
                        'aniview_outstream': this.auction,
                    },
                    finish: data.finish,
                    oeid: this.utils.window.OCM._oeid,
                    consent: this.utils.window.OCM.hasPurposeOneConsent,
                },
                event: "pba-stream",
                eventName: "Auction",
                options: event.options
            }

            // Send the aniview analytics data to wind runner
            fetch('https://windrunner.orangeclickmedia.com/pba-stream', {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain'
                },
                body: JSON.stringify(eventData)
            }).then((response) => {
                response.json().then(() => {
                    if (this.config.debug || this.aniview_config.debug) {
                        console.log(LOG_PREFIX + 'A pba stream was sent to wind runner successfully with data: ', eventData);
                    }
                })
            }).catch((err) => {
                console.error(err)
            })
        }
    }

    handleVideoEvents(eventName){
        if(!this.isHeaderBidding){
            return;
        }
        const eventData = {
            eventName: eventName,
            adUnit: 'aniview_outstream',
            auctionId: this.auctionId
        }

        let url = (eventName === 'bidViewable') ? 'https://windrunner.orangeclickmedia.com/pba-viewability' : 'https://windrunner.orangeclickmedia.com/pba-video';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: JSON.stringify(eventData)
        }).then((response) => {
            response.json().then((json) => {
                if (this.config.debug || this.aniview_config.debug) {
                    console.log(LOG_PREFIX + 'A pba video event was sent to wind runner successfully with data: ', eventData);
                }
            })
        }).catch((err) => {
            console.error(err)
        })
    }

    handleAdViewableImpression(event) {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'An new viewable impression event was triggered:', event)
        }

        this.handleVideoEvents('bidViewable');
    }

    handleAdVideoFirstQuartile(event) {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'An new first quartile event was triggered:', event)
        }

        this.handleVideoEvents('first_quartile');
    }

    handleAdVideoMidpoint(event) {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'An new mid point event was triggered:', event)
        }

        this.handleVideoEvents('midpoint');
    }

    handleAdVideoThirdQuartile(event) {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'An new third quartile event was triggered:', event)
        }

        this.handleVideoEvents('third_quartile');
    }

    handleAdVideoComplete(event) {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'An new video complete event was triggered:', event)
        }

        this.handleVideoEvents('complete');
    }

    handleAdClickThru(event) {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'An new ad click thru event was triggered:', event)
        }

        this.handleVideoEvents('click');
    }

    handleVCHackFloatingConfig(config) {
        if (!this.initialAvConfig?.floating && this.aniview_config.vchack) {
            config.floating = {
                "resize": true,
                "position": this.aniview_config.vchack_position || ((this.utils.is_mobile) ? "Top" : "Top-Right"),
                "closeButton": false,
                "floatOnImp": true,
                "resizingMethod": 1,
                "size": (this.utils.is_mobile) ? 0.4 : 0.5,
                "floatOnBottom": true,
                "backgroundColor": "White",
                "right": 0,
                "left": 0,
                "top": 0,
                "bottom": 0
            }
        }
    }

    createPassbacks() {
        this.utils.window.avConfig = (config, player) => {
            this.initialAvConfig = JSON.parse(JSON.stringify(config))
            config.openRtb = {
                site: {
                    ext: {
                        data: this.ADSQ_TARGETING
                    }
                }
            }
            config.pbanalytics = [{
                provider: "ocmpba",
                options: {
                    callback: (event) => {
                        this.handleAnalyticsCallback(event);
                    }
                }
            }];
            // config.bidderConfig = {
            //     currency: {
            //         adServerCurrency: "USD"
            //     }
            // }
        }

        this.utils.window.avPassback = (config, player) => {
            if (this.config.debug || this.aniview_config.debug) {
                console.log(LOG_PREFIX + 'Checking if I should run OCM.oipb for specific player')
                console.log(LOG_PREFIX + 'config.tagId !== this.aniview_config.playerTagId', config.tagId, this.aniview_config.playerTagId)
            }

            if (config.tagId !== this.aniview_config.playerTagId) {
                return
            }

            // Resize player if it's sticky
            if (this.aniview_config && this.aniview_config.sticky && this.aniview_config.sticky.enable) {
                const height = this.aniview_config?.sticky?.height || 0
                const width = this.aniview_config?.sticky?.width || 0
                player.resize(width, height);
            }

            if (config?.playOnViewPerc) {
                config.playOnViewPerc = 30
            }

            if (this.initialAvConfig?.floating) { // means it's definitely a floating player with a close button
                // hide the close button
                if (this.initialAvConfig?.closeButton) {
                    config.closeButton = false
                }
                config.floating.closeButton = false
                config.floating.backgroundColor = "White"
            }

            if (this.utils.window && this.utils.window.OCM && this.utils.window.OCM.handleCustomFloatingConfig && typeof this.utils.window.OCM.handleCustomFloatingConfig === 'function') {
                this.utils.window.OCM.handleCustomFloatingConfig(config, player);
                if(this.config.debug || this.aniview_config.debug){
                    console.log(LOG_PREFIX + 'Custom handling for the configuration: ', config);
                }
            } else {
                this.handleVCHackFloatingConfig(config);
                if(this.config.debug || this.aniview_config.debug){
                    console.log(LOG_PREFIX + 'VCHack handling for the configuration:', config);
                }
            }

            if(this.utils.window && this.utils.window.OCM && this.utils.window.OCM.handleCustomAdImpression && typeof this.utils.window.OCM.handleCustomAdImpression === 'function'){
                config.enableViewability = true;
            }

            player.updateGui(config)

            player.on('AdImpression', (event, eventData) => {

                if(this.utils.window && this.utils.window.OCM && this.utils.window.OCM.handleCustomAdImpression && typeof this.utils.window.OCM.handleCustomAdImpression === 'function'){
                    this.utils.window.OCM.handleCustomAdImpression(config, player, event);
                }

                // Resize vertical ads only for mobile devices
                if (this.utils.is_mobile) {
                    this.resizePlayerForVerticalAd(event, player);
                }

                // store the ad impression event internally
                this.handleAdImpression(event);
            });

            player.on('AdViewableImpression', (event, eventData) => {
                if (this.config.debug || this.aniview_config.debug) {
                    console.log(LOG_PREFIX + 'AdViewableImpression', event, eventData)
                }

                this.handleAdViewableImpression(event);

                if (this.initialAvConfig?.floating) {
                    if (this.initialAvConfig?.closeButton) {
                        config.closeButton = true
                    }
                    config.floating.closeButton = true
                    player.updateGui(config)
                }

                if(this.utils.window && this.utils.window.OCM && this.utils.window.OCM.handleCustomAdViewableImpression && typeof this.utils.window.OCM.handleCustomAdViewableImpression === 'function'){
                    config = this.utils.window.OCM.handleCustomAdViewableImpression(config, player, event);
                    player.updateGui(config);
                } else if(!(this.utils.window && this.utils.window.OCM && this.utils.window.OCM.handleCustomAdImpression && typeof this.utils.window.OCM.handleCustomAdImpression === 'function')){
                    if (!this.initialAvConfig?.floating && this.aniview_config.vchack) {
                        setTimeout(() => {
                            player.stopFloat()
                        }, 2000)
                    }
                }
            })

            player.on('AdVideoFirstQuartile', (event, eventData) => {
                if (this.config.debug || this.aniview_config.debug) {
                    console.log(LOG_PREFIX + 'AdVideoFirstQuartile', event, eventData);
                }
                this.handleAdVideoFirstQuartile(event);
            })

            player.on('AdVideoMidpoint', (event, eventData) => {
                if (this.config.debug || this.aniview_config.debug) {
                    console.log(LOG_PREFIX + 'AdVideoMidpoint', event, eventData);
                }
                this.handleAdVideoMidpoint(event);
            })

            player.on('AdVideoThirdQuartile', (event, eventData) => {
                if (this.config.debug || this.aniview_config.debug) {
                    console.log(LOG_PREFIX + 'AdVideoThirdQuartile', event, eventData);
                }
                this.handleAdVideoThirdQuartile(event);
            })

            player.on('AdVideoComplete', (event, eventData) => {
                if (this.config.debug || this.aniview_config.debug) {
                    console.log(LOG_PREFIX + 'AdVideoComplete', event, eventData);
                }

                this.handleAdVideoComplete(event);
            })

            player.on('AdClickThru', (event, eventData) => {
                if (this.config.debug || this.aniview_config.debug) {
                    console.log(LOG_PREFIX + 'AdClickThru', event, eventData);
                }
                this.handleAdClickThru(event);
            })

            player.on('AdError', (event, eventData) => {
                if (this.config.debug || this.aniview_config.debug) {
                    console.log(LOG_PREFIX + 'AdError', event, eventData);
                }

                if (typeof this.utils.window.OCM.oipb === 'function') {
                    this.utils.window.OCM.oipb(event, eventData)
                }
            })
        }
    }
}

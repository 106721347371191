const LOG_PREFIX = "[OCM][PageSense] "
let pageSense = async (utils) => {
  return new Promise((resolve, reject) => {
    let pageType = 'ROS';
    let subPaths = window.location.pathname.replace("/", "").split("/") || [];
    let paramsPaths = window.location.search || [];
    let hostPaths = window.location.host || [];

    let customPaths = ['taftotita','poioi-eimaste','epikoinonia','politiki-aporritou','diafimisteite','work-with-us'];
    // Keep only String values in subPaths (removes null and empty)
    subPaths = subPaths.filter(String);
    // The actual page sense goes here
    if (subPaths.length === 0) {
      pageType = "Home";
    } else if (subPaths.length === 1) {
      if (customPaths.indexOf(subPaths[0]) >= 0) {
        pageType = "Custom-1";
      } else {
        pageType = "Home";
      }
    } else if (subPaths.length < 5 ){
      pageType = "Category";
    } else if (subPaths.length === 5) {
      pageType = "Article";
    }

    window.googletag = window.googletag || {cmd: []}


    utils.contentLoaded().then(() => {

      // GAM targeting
      window.googletag.cmd.push(() => {
        googletag.pubads().setTargeting('ocm_site_billing', 'apexsports.gr')

        if (window && window.keyValues) {  
          Object.keys(window.keyValues).forEach(function eachKey(key) { 
            googletag.pubads().setTargeting(key, [String(window.keyValues[key])]);
          })
        }
      })  
      // Slot definitions
      // defineSlots(utils, pageType)

      //       // Ad div detection and display
      // let ad_placements = document.querySelectorAll('div[data-ocm-ad]')
      // ad_placements.forEach((ap) => {
      //  window.googletag.cmd.push(() => { googletag.display(ap.id) })
      // })
    })

    //     //ST patenta gia ta feeds per subdomain
    //     let subdomain = window.location.host.split('.')[0];
    //     utils.config.services.story_teller.rss_url = "https://" + subdomain + ".apexsports.gr/fb_xml_feed.php";

    resolve(pageType)
  });
};

// let defineSlots = (utils, pageType) => {

//   window.googletag.cmd.push(() => {

//      var billboard = googletag.sizeMapping()
//     .addSize([0, 0], [[300,600],[300,250]])
//     .addSize([340,0], [[300,600],[300,250],[336,280]])
//     .addSize([768, 0], [[728,90]])
//     .addSize([1024, 0],[[970,250],[970,90],[728,90]])
//     .build();

//     var sidebar = googletag.sizeMapping()
//     .addSize([0, 0], [[300,250],[300,600]])
//     .addSize([340,0], [[300,250],[300,600],[336,280],[320,480]])
//     .addSize([768, 0], [[300,250],[300,600],[336,280],[320,480],[160,600]])
//     .addSize([1024, 0],[[300,250],[336,280],[300,600],[320,480],[160,600]])
//     .build();



//     if (pageType==="Home") {
//    	  googletag.defineSlot('/75351959,22896694197/apexsports.gr/billboard1',[[970,250],[970,90],[728,90],[336,280],[300,250],[300,600]],'billboard1').defineSizeMapping(billboard).addService(googletag.pubads());
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/billboard2',[[970,250],[970,90],[728,90],[336,280],[300,250],[300,600]],'billboard2').defineSizeMapping(billboard).addService(googletag.pubads());
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/billboard3',[[970,250],[970,90],[728,90],[336,280],[300,250],[300,600]],'billboard3').defineSizeMapping(billboard).addService(googletag.pubads());
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/sidebar1',[[300,600],[336,280],[300,250],[320,480],[160,600]],'sidebar1').defineSizeMapping(sidebar).addService(googletag.pubads());
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/sidebar2',[[300,600],[336,280],[300,250],[320,480],[160,600]],'sidebar2').defineSizeMapping(sidebar).addService(googletag.pubads());
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/sidebar3',[[300,600],[336,280],[300,250],[320,480],[160,600]],'sidebar3').defineSizeMapping(sidebar).addService(googletag.pubads());
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/sidebar4',[[300,600],[336,280],[300,250],[320,480],[160,600]],'sidebar4').defineSizeMapping(sidebar).addService(googletag.pubads());
//     }
//     if (pageType==="Category") {
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/billboard1',[[970,250],[970,90],[728,90],[336,280],[300,250],[300,600]],'billboard1').defineSizeMapping(billboard).addService(googletag.pubads());
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/billboard2',[[970,250],[970,90],[728,90],[336,280],[300,250],[300,600]],'billboard2').defineSizeMapping(billboard).addService(googletag.pubads());
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/billboard3',[[970,250],[970,90],[728,90],[336,280],[300,250],[300,600]],'billboard3').defineSizeMapping(billboard).addService(googletag.pubads()); // TEST να βγουν και απο hb//
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/sidebar1',[[300,600],[336,280],[300,250],[320,480],[160,600]],'sidebar1').defineSizeMapping(sidebar).addService(googletag.pubads());
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/sidebar2',[[300,600],[336,280],[300,250],[320,480],[160,600]],'sidebar2').defineSizeMapping(sidebar).addService(googletag.pubads());
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/sidebar3',[[300,600],[336,280],[300,250],[320,480],[160,600]],'sidebar3').defineSizeMapping(sidebar).addService(googletag.pubads()); // TEST να βγουν και απο hb//
//       googletag.defineSlot('/75351959,22896694197/apexsports.gr/sidebar4',[[300,600],[336,280],[300,250],[320,480],[160,600]],'sidebar4').defineSizeMapping(sidebar).addService(googletag.pubads()); // TEST να βγουν και απο hb//

//     }
// //     if (pageType==="Article") {
// //       googletag.defineSlot('/75351959,22896694197/apexsports.gr/sidebar1',[[300,600],[336,280],[300,250],[320,480],[160,600]],'sidebar1').defineSizeMapping(sidebar).addService(googletag.pubads());
// //       googletag.defineSlot('/75351959,22896694197/apexsports.gr/sidebar2',[[300,600],[336,280],[300,250],[320,480],[160,600]],'sidebar2').defineSizeMapping(sidebar).addService(googletag.pubads());
// //       googletag.defineSlot('/75351959,22896694197/apexsports.gr/sidebar3',[[300,600],[336,280],[300,250],[320,480],[160,600]],'sidebar3').defineSizeMapping(sidebar).addService(googletag.pubads());
// //       googletag.defineSlot('/75351959,22896694197/apexsports.gr/sidebar4',[[300,600],[336,280],[300,250],[320,480],[160,600]],'sidebar4').defineSizeMapping(sidebar).addService(googletag.pubads());
// //     }
//   })
// }
module.exports = pageSense;
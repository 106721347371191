//waterfall: Teads > Aniview
const LOG_PREFIX = "[OCM][Custom] "
const Aniview = require('../../services/aniview')

let customCode = (utils) => {
  return new Promise((resolve, reject) => {
    utils.contentLoaded().then(() => {
      runOutstream(utils)
    })
    resolve(true)
  });
};

let runOutstream = (utils) => {
  if (utils.config.debug) {
    console.log(LOG_PREFIX + 'Running outstream')
  }

  (function (w, d, s) {
    try {
      d = w.top.document || d;
      w = w.top.document ? w.top : w;
    } catch (e) {
    }

    if (w.teads !== undefined) {
      return;
    }

    let exclude_urls = [
      //{'url': '2935139'}
    ]

    for (var z = 0; z < exclude_urls.length; z++) {
      if (w.location.href.indexOf(exclude_urls[z].url) > -1) {
        return;
      }
    }

    var selector = document.querySelectorAll(".entry-content > p")[0];
    if (!selector) {
      return
    }

    var ttag = function () {
      w.teads.page(215203).placement(199296, {
        slider: {allow_corner_position: false, allow_top_position: false},
        "css":"margin: 0px auto 25px; max-width: 650px;",
        "format": "inread",
        "slot": {"insertAfter": true, "btf": false, "selector": selector, "minimum": 0},
        callbacks: {
          AdStarted: function (){
            const tc = document.createElement('script');
            tc.src = 'https://cdn.orangeclickmedia.com/assets/tc.js';
            tc.type = 'text/javascript';
            document.head.appendChild(tc);
          }
        }
      }).passback(function () {
        window.top.OCM.oipb = () => {
          vidverto();
        }

        utils.config.services.aniview.active = true
        const av = new Aniview(utils, utils.config)
        av.run()

      }).serve();
    };
    if (w.teads && w.teads.page) {
      ttag();
    } else if (!w.teadsscript) {
      var protocol = w.location.protocol.match(/^https?:$/) ? w.location.protocol : 'https:';
      s.src = protocol + '//a.teads.tv/media/format/v3/teads-format.min.js';
      s.async = true;
      s.onload = ttag;
      w.teadsscript = d.getElementsByTagName('head')[0].appendChild(s);
    }
  })(window, document, document.createElement('script'));
}


const vidverto = () => {
  var selector = document.querySelectorAll(".ocm-player")[0];
  var script = document.createElement('script');
  script.setAttribute('id', '_vidverto-30410e657d218e11006ca25c65b10bb9');
  script.text = '  (function (v,t) {\n' +
    ' var a = t.createElement("script");\n' +
    ' a.src = "https://ad.vidverto.io/vidverto/js/aries/v1/invocation.js";\n' +
    ' a.setAttribute("fetchpriority","high");\n' +
    ' var r = v.top;\n' +
    ' r.document.head.appendChild(a);\n' +
    ' v.self!==v.top&&(v.frameElement.style.cssText="width:0px!important;height:0px!important;");\n' +
    ' r.aries = r.aries||{};\n' +
    ' r.aries.v1 = r.aries.v1||{commands:[]};\n' +
    ' var c = r.aries.v1;\n' +
    ' c.commands.push(\n' +
    '   (function(){\n' +
    '     var d=document.getElementById("_vidverto-30410e657d218e11006ca25c65b10bb9");\n' +
    '     d.setAttribute("id",(d.getAttribute("id")+(new Date()).getTime()));\n' +
    '     var t = v.frameElement|| d;\n' +
    '     c.mount("10541",t,{width:720,height:405})})\n' +
    ' )\n' +
    '})(window,document);'
  selector.append(script);
  console.log('vidverto added')  
}


module.exports = customCode;
const LOG_PREFIX = "[OCM][Fizz] ";

module.exports = class Fizz {
    utils;
    config;
    fizz_config;
    slots = [];
    lazyload;
    headerBiddingFunctionality;

    // user engagement
    engaging = false; // by default user is not engaged until a event is triggered
    engagementTimer = null;

    // slots observer
    slotsObserver = null;
    INTERSECTION_OBSERVER_TIMEOUT = 1000;
    FULLSCREEN_INTERVAL = 7000;
    TEADS_MAX_VIDEO_DURATION = 30000; // 30 seconds
    INTERSECTION_OBSERVER_OPTIONS = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
    }

    // refresh interval
    isRefreshIntervalStarted = false;
    TRIGGER_REFRESH_INTERVAL = 3000;

    constructor(utils, config) {
        this.utils = utils;
        this.config = config;
        this.fizz_config = config.services.fizz;
        this.headerBiddingFunctionality = config.services.hasOwnProperty("header_bidding") && config.services.header_bidding.active
            ? config.services.header_bidding.functionality
            : null;
    }

    run() {
        if (this.utils.url.includes('google_preview=')) {
            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + "SEIZE FIRE. Will not run in google_preview mode.");
            }

            return
        }

        if (!this.utils.allowPageType(this.fizz_config.page_types)) {
            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + 'Page type not allowed, terminating process', this.fizz_config.page_types);
            }
            return
        }

        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + "Running...");
        }

        // start listening for user engagement
        this.trackUserEngagement();

        // create an observer to track slots visibility related to viewport
        this.slotsObserver = this.createSlotsObserver();

        // when a slot is rendered then add it to slots list
        this.slotRenderEndedListener();

        this.adRenderSucceededListener();
    }

    allowByAdUnit(adUnitPath) {
        return !!this.fizz_config.ads_whitelist.find(ad => ad.path === adUnitPath);
    }

   isHBOnly(adUnitPath){
       const adUnit = this.fizz_config.ads_whitelist.find(ad => ad.path === adUnitPath);
       return !!(adUnit && adUnit.hb_only);
   }

    allowByAdvertiser(advertiserId) {
        if (this.fizz_config.advertisers_whitelist && this.fizz_config.advertisers_whitelist.length === 0) {
            return true;
        }
        const advertiser = this.fizz_config.advertisers_whitelist.find(ad => ad.id === advertiserId);
        if (advertiser) {
            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + `Found whitelisted advertiser ${advertiser.name}`);
            }
        }
        return !!advertiser;
    }

    removeFromSlots(slotElementId, slotAdUnitPath, slotResponse) {
        if (this.slots.hasOwnProperty(slotElementId)) {
            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX, `Removing ad slot "${slotAdUnitPath}" due to not whitelisted advertiserId = ${slotResponse.advertiserId}`);
            }
            this.slots.splice(this.slots.findIndex(slotElementId), 1);
        }
    }

    getAdDivElement(adUnitCode) {
        let adDiv = null
        if (this.utils.config.services.lazyload.active) {
            adDiv = this.utils.window.document.querySelector('[data-oau-code="' + adUnitCode + '"]')
        } else {
            let slot = this.utils.window.googletag.pubads().getSlots().filter((slot) => {
                return slot.getAdUnitPath() === adUnitCode
            })
            if (slot.length && typeof slot[0] === 'object') {
                adDiv = this.utils.window.document.getElementById(slot[0].getSlotElementId())
            }
        }

        return adDiv
    }

    /**
     * Listen for user engagement. We listen for interaction events that the user might trigger. If
     * a user triggers one or more of those events then we update the flag for user engagement. In this way
     * we can track completely the user interaction.
     */
    trackUserEngagement() {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + "Running user engagement listener");
        }

        ["touchstart", "touchmove", "click", "scroll", "keyup"].forEach((e) => {
            this.utils.window.addEventListener(
                e,
                this.utils.throttle(() => {
                    // Reset to true if any of those events occur
                    this.engaging = true;
                    if (this.config.debug || this.fizz_config.debug) {
                        console.log(LOG_PREFIX + "User engaged");
                    }
                    // Set a timer to falsify this.engaging
                    if (this.engagementTimer) clearTimeout(this.engagementTimer);
                    this.engagementTimer = setTimeout(() => {
                        this.engaging = false;
                        if (this.config.debug || this.fizz_config.debug) {
                            console.log(LOG_PREFIX + "User disengaged");
                        }
                    }, 10000);
                }, 1000)
            );
        });
    }

    /**
     * Observer that will track if the slots are viewable and update the
     * timestamp that an ad has been seen by the user
     */
    createSlotsObserver() {
        return new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        const slotElementId = entry?.target?.id;
                        if (slotElementId && this.utils.isSlotInViewport(slotElementId)) {
                            this.slots[slotElementId].viewedAt = Date.now();
                            if (this.config.debug || this.fizz_config.debug) {
                                console.log(LOG_PREFIX + `[Intersection Observer] Slot "${entry.target.id}" is visible at viewport`);
                            }
                            this.slotsObserver.unobserve(entry.target);
                        } else {
                            if (this.config.debug || this.fizz_config.debug) {
                                console.log(LOG_PREFIX + `[Intersection Observer] Slot "${entry.target.id}" is out of viewport`);
                            }
                        }
                    }, this.INTERSECTION_OBSERVER_TIMEOUT);
                }
            })
        }, this.INTERSECTION_OBSERVER_OPTIONS);
    }

    /**
     * We set a listener that track when an ad is completely rendered. If an ad is rendered successfully
     * then we collect all the rendered slots and after that we set an interval that will refresh only the
     * ads that are allowed to be refreshed.
     */
    slotRenderEndedListener() {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + "Looking for rendered slots");
        }

        this.utils.window.googletag.cmd.push(() => {
            this.utils.window.googletag.pubads().addEventListener("slotRenderEnded", (event) => {

                if (!this.isRefreshIntervalStarted) {
                    this.isRefreshIntervalStarted = true;
                    this.startRefreshInterval(); // start refresh interval
                }

                const slotAdUnitPath = event.slot.getAdUnitPath();
                const slotElementId = event.slot.getSlotElementId();
                const slotElement = this.utils.window.document.getElementById(slotElementId);
                const slotResponse = !event.isEmpty ? event.slot.getResponseInformation() : null;

                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + `Checking if ad unit "${slotAdUnitPath}" is in whitelisted ad units`, this.fizz_config.ads_whitelist);
                }

                // Don't refresh ad units that aren't whitelisted
                if (!this.allowByAdUnit(slotAdUnitPath)) {
                    if (this.config.debug || this.fizz_config.debug) {
                        console.log(LOG_PREFIX + `Blocking refresh of "${slotAdUnitPath}" due to not whitelisted ad unit path`);
                    }
                    return null;
                }

                // Remove slots that are not allowed to be refreshed based on the advertiser
                if (!event.isEmpty) {
                    if (this.config.debug || this.fizz_config.debug) {
                        console.log(LOG_PREFIX + `Checking "${slotAdUnitPath}" for whitelisted advertiserId = ${slotResponse.advertiserId}`);
                    }

                    if (!this.allowByAdvertiser(slotResponse.advertiserId)) {
                        if (this.config.debug || this.fizz_config.debug) {
                            console.log(LOG_PREFIX + `Removing "${slotAdUnitPath}" from fizz slots due to not allowed by advertiser`);
                        }
                        this.removeFromSlots(slotElementId, slotAdUnitPath, slotResponse);
                        return null;
                    }

                    // Get the max height for current ad unit. Attach min height only when the max height ad is rendered.
                    const maxHeight = this.getMaxHeightForSlot(slotAdUnitPath);
                    const iframe = this.utils.window.document.querySelector(`#${slotElementId} iframe`); // creative
                    if (iframe) {
                        const height = iframe.clientHeight || iframe.offsetHeight;
                        if (height && maxHeight === height) {
                            if (!slotElementId.includes('ocm_sticky') && !slotElement.parentNode.classList.contains('ocm-mis-wrapper')) {
                                slotElement.style.minHeight = maxHeight + 'px';
                                if (this.config.debug || this.fizz_config.debug) {
                                    console.log(LOG_PREFIX, 'Attaching min height to ', slotAdUnitPath);
                                }
                            }
                        }
                    }
                }

                // Create new slots entry in fizz
                if (!this.slots.hasOwnProperty(slotElementId)) {
                    const fizzInterval = +this.fizz_config.interval;
                    this.slots[slotElementId] = {
                        gptslot: event.slot,
                        div: slotElementId,
                        code: slotAdUnitPath,
                        renderedAt: Date.now(),
                        isEmpty: event.isEmpty,
                        viewedAt: null,
                        hb_only: this.isHBOnly(slotAdUnitPath),
                        adUnitInterval: fizzInterval / 2,
                        refreshTurn: "HB"
                    };

                    // observe slot to update "viewedAt" field
                    if (slotElement) {
                        this.slotsObserver.observe(this.utils.window.document.getElementById(slotElementId));

                        if (this.config.debug || this.fizz_config.debug) {
                            console.log(LOG_PREFIX + `Created a new slot for "${slotElementId}"`, this.slots[slotElementId]);
                        }
                    }
                } else {
                    // Update time entry for existing slot in fizz
                    this.slots[slotElementId].renderedAt = Date.now();
                    if (this.config.debug || this.fizz_config.debug) {
                        console.log(LOG_PREFIX + `Updated time for slot "${slotElementId}"`, this.slots[slotElementId]);
                    }
                }

                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + 'All Slots: ', this.slots);
                }
            });
        });

    }

    adRenderSucceededListener() {
        this.utils.window.ocmpbjs = this.utils.window.ocmpbjs || {que: []};
        this.utils.window.ocmpbjs.que.push(() => {
            this.utils.window.ocmpbjs.onEvent('adRenderSucceeded', (bidResponse) => {

                if (!this.isRefreshIntervalStarted) {
                    this.isRefreshIntervalStarted = true;
                    this.startRefreshInterval(); // start refresh interval
                }

                const adUnitCode = bidResponse.bid.adUnitCode;
                const fizzInterval = +this.fizz_config.interval;
                this.slots[adUnitCode] = {
                    gptslot: null,
                    div: adUnitCode,
                    code: adUnitCode,
                    renderedAt: Date.now(),
                    isEmpty: false,
                    viewedAt: Date.now() + 1000,
                    hb_only: this.isHBOnly(adUnitCode),
                    adUnitInterval: fizzInterval / 2,
                    refreshTurn: "HB"
                };

                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + 'All Slots: ', this.slots);
                }
            });
        });
    }

    /**
     * Get the max height from the ad unit sizes
     * @param {string} code
     */
    getMaxHeightForSlot(code) {
        const adUnit = this.utils.window.OCM.ad_units.find(au => au.code === code);
        if (!adUnit) {
            return 0;
        }
        return adUnit.sizes.reduce((max, current) => Math.max(max, current[1]), 0);
    }

    /**
     * Get slot from the global object by code
     * @param {string} code - ad unit path
     */
    getSlotByCode(code) {
        const slotId = Object.keys(this.slots).find(key => this.slots[key].code === code);
        return this.slots[slotId];
    }

    /**
     * Remove irrelevant sizes from banner/video options based on the height of the creative
     * @param {string} code - ad unit path
     * @param {Object} mediaTypes - media types from ad unit
     */
    adjustMediaTypesSizes(code, mediaTypes) {
        const mt = { ...mediaTypes };
        const slot = this.getSlotByCode(code);

        if (slot) {
            const container = this.utils.window.document.querySelector(`#${slot.div}`); // container of creative
            const iframe = this.utils.window.document.querySelector(`#${slot.div} iframe`); // creative

            if (container && iframe) {
                const height = iframe.clientHeight || iframe.offsetHeight;
                const hasMinHeight = container.style && container.style.minHeight && container.style.minHeight != "";

                if (height) {
                    if (mt.banner) {
                        mt.banner.sizes = mediaTypes.banner.sizes.filter(size => hasMinHeight ? (size[1] === height || size[1] < height) : (size[1] === height));

                        if (mt.banner.sizes.length === 0) {
                            delete mt.banner;
                        }
                    }

                    if (mt.video) {
                        mt.video.playerSize = mediaTypes.video.playerSize.filter(size => hasMinHeight ? (size[1] === height || size[1] < height) : (size[1] === height));
                        if (mt.video.playerSize.length === 0) {
                            delete mt.video;
                        }
                    }
                }
            }
        }
        return mt;
    }

    /**
     * Prepares the ad units that we need to refresh
     * @param {Array} bubbles - slots that we track
     * @param {boolean} dropAmazon - flag to drop amazon bids
     * @param {boolean} adjustMediaTypes - flag to adjust sizes at media types
     */
    determineHbAdUnits(bubbles, dropAmazon = false, adjustMediaTypes = false) {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + "Determining HB ad units");
        }

        let refresh_hb_ads = [];

        for (const bubble of bubbles) {
            for (let adUnit of this.utils.window.OCM.ad_units) {
                if (adUnit.code === bubble.code) {
                    let ortb2Imp = (adUnit?.ortb2Imp) ? JSON.parse(JSON.stringify(adUnit.ortb2Imp)) : { ext: { data: {} } }
                    ortb2Imp.ext.data['fizz'] = true

                    // Adjust media types sizes to avoid CLS issues by removing irrelevant sizes from refresh
                    if (adjustMediaTypes) {
                        const mediaTypes = this.adjustMediaTypesSizes(adUnit.code, adUnit.mediaTypes);
                        const hasSizes = (mediaTypes.banner && mediaTypes.banner.sizes && mediaTypes.banner.sizes.length) ||
                            (mediaTypes.video && mediaTypes.video.playerSize && mediaTypes.video.playerSize.length);
                        if (hasSizes) {
                            refresh_hb_ads.push({
                                code: adUnit.code,
                                mediaTypes: mediaTypes,
                                bids: dropAmazon ? adUnit.bids.filter(bid => bid.bidder !== 'amazon') : adUnit.bids,
                                ortb2Imp: ortb2Imp,
                            });
                        }
                    } else {
                        refresh_hb_ads.push({
                            code: adUnit.code,
                            mediaTypes: adUnit.mediaTypes,
                            bids: dropAmazon ? adUnit.bids.filter(bid => bid.bidder !== 'amazon') : adUnit.bids,
                            ortb2Imp: ortb2Imp,
                        });
                    }
                }
            }
        }

        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + "Determined HB ad units", refresh_hb_ads);
        }

        return refresh_hb_ads;
    }

    refreshHb(bubbles) {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + "in refreshHb");
        }

        let refresh_hb_ads = this.determineHbAdUnits(bubbles, true, true);

        if (refresh_hb_ads.length) {
            this.utils.window.ocmpbjs.que.push(() => {
                this.utils.window.ocmpbjs.removeAdUnit()
                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + " Removing old ad units");
                }
                this.utils.window.ocmpbjs.addAdUnits(refresh_hb_ads);
                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + "Adding new ad units", refresh_hb_ads);
                }
                this.utils.window.ocmpbjs.requestBids({
                    adUnitCodes: refresh_hb_ads.map((unit) => {
                        return unit.code
                    }),
                    bidsBackHandler: (bidResponses, timeout, auctionId) => {
                        if (this.config.debug || this.fizz_config.debug) {
                            console.log(LOG_PREFIX + "Auction has finished and the bids are : ", bidResponses);
                        }

                        // filter out rejected bids
                        Object.keys(bidResponses).forEach(adUnit => {
                            bidResponses[adUnit].bids = bidResponses[adUnit].bids.filter(b => b.statusMessage !== 'bidRejected' && b.status !== 'bidRejected');
                        });

                        // filter out empty bids
                        Object.keys(bidResponses).forEach(adUnit => {
                            if (!bidResponses[adUnit].bids.length) {
                                delete bidResponses[adUnit]
                            }
                        });

                        if (Object.keys(bidResponses).length > 0) {
                            this.renderBidResponses(bidResponses);
                        }

                        // Resetting slots properties based on bids responses
                        bubbles.forEach(b => {
                            this.slots[b.div].isEmpty = bidResponses[b.div] && bidResponses[b.div].bids && bidResponses[b.div].bids.length || false;
                        });
                    }
                })
            });
        } else {
            // Resetting slots properties
            bubbles.forEach(b => {
                this.slots[b.div].refreshTurn = "GAM";
            });
        }
    }

    renderBidResponses(bidResponses) {
        for (let adUnitCode in bidResponses) {
            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + 'Found bid for #' + adUnitCode, bidResponses[adUnitCode])
            }

            const divId = adUnitCode;
            const adDiv = this.getAdDivElement(adUnitCode)

            if (adDiv) {
                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + 'Ad div = ', adDiv)
                }

                let frmEl = this.utils.window.document.createElement('iframe');

                frmEl.setAttribute('id', divId + '_ocm_hb')
                frmEl.setAttribute('style', 'border:0; overflow:hidden; margin:0px auto;display:block;');
                frmEl.width = '1'
                frmEl.height = '1'
                frmEl.setAttribute('frameborder', '0')
                frmEl.setAttribute('marginheight', '0')
                frmEl.setAttribute('marginwidth', '0')
                frmEl.setAttribute('scrolling', 'no')
                frmEl.setAttribute('sandbox', 'allow-forms allow-popups allow-popups-to-escape-sandbox allow-pointer-lock allow-same-origin allow-scripts allow-top-navigation-by-user-activation')

                let adServerTargeting = this.utils.window.ocmpbjs.getAdserverTargetingForAdUnitCode(divId)
                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + 'adServerTargeting', adServerTargeting)
                }

                // If any bidders return any creatives
                if (adServerTargeting && adServerTargeting['hb_adid']) {
                    if (this.config.debug || this.fizz_config.debug) {
                        console.log(LOG_PREFIX + 'Rendering ad for ' + divId)
                    }

                    // remove deprecated divs
                    const deprecatedElements = adDiv.querySelectorAll('script, div, iframe, ins');
                    deprecatedElements.forEach(div => div.remove());

                    adDiv.append(frmEl);
                    // frame body margin:8px 0 fix (?)
                    let iframeDoc = frmEl.contentDocument || frmEl.contentWindow.document

                    this.utils.window.ocmpbjs.renderAd(iframeDoc, adServerTargeting['hb_adid'])

                    iframeDoc.body.insertAdjacentHTML('afterbegin', `<style>body {margin:0px !important}</style>`)
                    iframeDoc.body.style.margin = '0px !important'

                    this.slotsObserver.observe(adDiv);

                    // adDiv.setAttribute('style', 'display:block;overflow:hidden;margin:0 auto;');
                }
            } else {
                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + 'Ad div #' + divId + ' not found')
                }
            }
        }
    }

    /**
     * Verify that an element contains a video or an iframe with a video inside.
     */
    slotContainsVideo(slot) {
        const div = this.utils.window.document.getElementById(slot.div);
        const now = Date.now();

        // teads
        const timeSinceRendered = (now - slot.renderedAt) || 0;
        const hasTeadsDiv = !!(div && div.querySelector('.teads-inread') && timeSinceRendered <= this.TEADS_MAX_VIDEO_DURATION); // we assume that tead video have a max duration

        // video
        const hasVideoAtDiv = !!(div && div.querySelector('video'));

        // video inside iframe
        const iframe = div && div.querySelector('iframe');
        const hasVideoAtIframe = !!(iframe && iframe.contentDocument && iframe.contentDocument.querySelector('video'));

        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + `Slot ID: "${slot.div}" hasVideo: ${hasVideoAtDiv} hasTeads: ${hasTeadsDiv} hasVideoInIframe: ${hasVideoAtIframe}`);
        }

        return hasVideoAtDiv || hasVideoAtIframe || hasTeadsDiv;
    }

    /**
     * After the end of each interval, we loop through the available slots and gather the one that needs refresh. After gathering all
     * the slots, we will trigger a certain flow based on header bidding functionality and some extra flags.
     */
    startRefreshInterval() {
        setInterval(() => {
            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + "Refresh interval triggered");
            }

            if (this.engaging) {
                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + `User is engaged during refresh interval trigger.`);
                }

                const now = Date.now();
                const hbBubbles = [];
                const gamBubbles = [];

                Object.keys(this.slots).forEach((key) => {
                    const slot = this.slots[key];
                    const timeSinceRendered = now - slot.renderedAt || 0;
                    const timeSinceViewed = now - slot.viewedAt || 0;
                    const adUnitInterval = (slot.div && slot.div.includes('fullscreen')) ? this.FULLSCREEN_INTERVAL : slot.adUnitInterval ;
                    const isHBOnly = !!slot.hb_only;

                    // Don't refresh slots with video
                    if (this.slotContainsVideo(slot)) {
                        if (this.config.debug || this.fizz_config.debug) {
                            console.log(LOG_PREFIX + `Slot "${slot.div}" playing video. Skipping refresh for this slot.`);
                        }
                        return;
                    }

                    if (slot.refreshTurn === "HB") {
                        if (timeSinceRendered >= adUnitInterval && timeSinceViewed >= 5000) {
                            if (this.config.debug || this.fizz_config.debug) {
                                console.log(LOG_PREFIX + slot.div + `HB Slot "${slot.div}" passed time requirements. Turn: ${slot.refreshTurn}`);
                            }
                            if (this.utils.isSlotInViewport(slot.div)) {
                                hbBubbles.push(slot);
                            } else {
                                if (this.config.debug || this.fizz_config.debug) {
                                    console.log(LOG_PREFIX + `HB Slot "${slot.div}" not inside viewport`);
                                }
                            }
                        }
                    } else if (slot.refreshTurn === "GAM") {
                        const currentInterval = (this.fizz_config.interval - adUnitInterval) > 0 ? (this.fizz_config.interval - adUnitInterval) : this.fizz_config.interval;
                        if (timeSinceRendered >= currentInterval && timeSinceViewed >= 3000) {
                            if (this.config.debug || this.fizz_config.debug) {
                                console.log(LOG_PREFIX + slot.div + `GAM Slot "${slot.div}" passed time requirements`);
                            }
                            if (this.utils.isSlotInViewport(slot.div) && !isHBOnly) {
                                gamBubbles.push(slot);
                            } else {
                                if (this.config.debug || this.fizz_config.debug) {
                                    console.log(LOG_PREFIX + `GAM Slot "${slot.div}" not inside viewport`);
                                }
                            }
                        }
                    }
                });

                this.refreshHbBubbles(hbBubbles);

                this.refreshGAMBubbles(gamBubbles);

            } else {
                if (this.config.debug || this.fizz_config.debug) {
                    console.log(LOG_PREFIX + `No user engagement during refresh interval trigger`);
                }
            }

        }, this.TRIGGER_REFRESH_INTERVAL);
    }

    refreshHbBubbles(bubbles) {
        if (bubbles.length) {
            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + "Found HB bubbles: ", bubbles);
            }

            // refresh bubbles using header bidding
            this.refreshHb(bubbles);

            // Resetting slots properties
            bubbles.forEach(b => {
                this.slots[b.div].viewedAt = null;
                this.slots[b.div].renderedAt = Date.now();
                this.slots[b.div].refreshTurn = b.hb_only ? "HB" : "GAM";
            });

            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + "Resetting all HB slots after refresh", this.slots);
            }

        } else {
            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + "No HB bubbles found");
            }
        }
    }

    refreshGAMBubbles(bubbles) {
        if (bubbles.length) {
            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + "Found GAM bubbles: ", bubbles);
            }

            switch (this.headerBiddingFunctionality) {
                case null: // HB is inactive
                    this.refreshDfp(bubbles);
                    break;
                case "no_adserver":
                    this.refreshHb(bubbles);
                    break;
                case "lazyload_v1":
                case "lazyload_v2":
                    this.refreshDfpHbLazyload(bubbles);
                    break;
                default:
                    //adserver
                    this.refreshDfpHb(bubbles);
                    break;
            }

            // Resetting slots properties
            bubbles.forEach(b => {
                this.slots[b.div].viewedAt = null;
                this.slots[b.div].renderedAt = Date.now();
                this.slots[b.div].refreshTurn = "HB";
            });

            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + "Resetting all GAM slots after refresh", this.slots);
            }

        } else {
            if (this.config.debug || this.fizz_config.debug) {
                console.log(LOG_PREFIX + "No GAM bubbles found");
            }
        }
    }

    refreshDfp(bubbles) {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + "in refreshDfp");
        }

        let slots = [];
        Object.keys(bubbles).forEach((bubble) => {
            this.utils.window.googletag.cmd.push(() => {
                bubbles[bubble].gptslot.setTargeting("ocmFizz", ["true"]);
            });
            slots.push(bubbles[bubble].gptslot);
        });

        this.utils.window.googletag.cmd.push(() => {
            this.utils.window.googletag.pubads().refresh(slots);
        });
    }

    refreshDfpHbLazyload(bubbles) {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + "in refreshDfpHbLazyload");
        }

        for (const bubble of bubbles) {
            let element = document.querySelector(
                'div[data-oau-code="' + bubble.code + '"]'
            );
            if (element && !element.hasAttribute("data-lazyincluded-by-ocm")) {
                // This should trigger the lazyload combust
                element.removeAttribute("data-lazyloaded-by-ocm");
                element.removeAttribute("data-oau-code");
                this.utils.window.googletag.cmd.push(() => {
                    bubble.gptslot.setTargeting("ocmFizz", ["true"]);
                });
            }
        }
    }

    refreshDfpHb(bubbles) {
        if (this.config.debug || this.fizz_config.debug) {
            console.log(LOG_PREFIX + "in refreshHbDfp");
        }

        this.utils.window.ocmpbjs.adUnits = [];

        let refresh_hb_ads = this.determineHbAdUnits(bubbles, false, false);

        this.utils.window.googletag.cmd.push(() => {
            this.utils.window.ocmpbjs.que.push(() => {
                this.utils.window.ocmpbjs.addAdUnits(refresh_hb_ads);
                this.utils.window.ocmpbjs.requestBids({
                    adUnitCodes: refresh_hb_ads.map(function (unit) {
                        return unit.code;
                    }),
                    bidsBackHandler: () => {
                        let slots = [];
                        try {
                            Object.keys(bubbles).forEach((bubble) => {
                                this.utils.window.googletag.cmd.push(() => {
                                    bubbles[bubble].gptslot.setTargeting("ocmFizz", ["true"]);
                                });

                                // TODO this will have to play along with the viewability service
                                // if (this.config.services.hasOwnProperty('viewability') && this.config.services.viewability.active) {
                                //     var viewability = null
                                //     if (viewability = ocmViewabilityPrediction(bubbles[bubble].gptslot)) {
                                //         console.log('setTargeting', viewability)
                                //         bubbles[bubble].gptslot.setTargeting('ocmViewability', [viewability])
                                //     }
                                // }

                                slots.push(bubbles[bubble].gptslot);
                            });
                        } catch (e) {
                            console.error("OCM Fizz: ", e);
                        }

                        if (slots.length) {
                            this.utils.window.ocmpbjs.setTargetingForGPTAsync(
                                slots.map(function (slot) {
                                    return slot.getAdUnitPath();
                                })
                            );
                            this.utils.window.googletag.pubads().refresh(slots);
                            this.utils.window.ocmpbjs.initAdserverSet = true;
                            if (this.config.debug || this.fizz_config.debug) {
                                console.log(LOG_PREFIX + "=> HB: Called initAdServer");
                            }
                        }
                    },
                });
            });
        });
    }

}

const browser = require('./browser')
const isMobile = require('is-mobile')
const axios = require('axios')

const GEOLOCATION_WORKER_URL = 'https://geo.ocm.workers.dev'
const ERROR_CAPTURE_WORKER_URL = 'https://errors.ocm.workers.dev'
const ADS_BY_GOOGLE_URL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
const LOG_PREFIX = '[OCM][Utils] '
const Log = require('./log')

module.exports = class Utils {
    config
    browser
    start
    window
    doc_head
    doc_body
    screen_width
    screen_height
    is_mobile
    hostname
    url
    willLog
    willSampleWarnings
    willSampleErrors
    log_blacklist = []

    constructor(config) {
        this.config = config;
        this.browser = browser;
        this.start = new Date();
        this.serviceStates = this.getServiceStates();
        this.window = this.config.hasOwnProperty('use_window_top') ? (this.config.use_window_top ? window.top : window) : window.top
        this.doc_head = this.window.document.head || this.window.document.getElementsByTagName("head")[0];
        this.doc_body = this.window.document.body || this.window.document.getElementsByTagName("body")[0];
        this.screen_width = this.screenWidth()
        this.screen_height = this.screenHeight()
        this.is_mobile = isMobile()
        this.hostname = this.window.location.hostname
        this.url = this.window.location.href

        this.calculateLoggingSamples()

        if (this.config?.log) {
            this.log = new Log(this.config.build, this.hostname, this.url)
        }

        this.log_blacklist = [
            'FPD request size has exceeded maximum request size',
            'missing parameters for real time module',
            'module criteo is loading external JavaScript',
            'Rubicon: Filtered FPD key:',
            'User sync not allowed',
            'Continuing without bids',
            'No valid bid requests returned for auction',
            'fetch encountered an error',
            'xhr timeout after',
            'TCF2 denied device access for',
            'has been rendered before',
            'criteoIdSystem: unable to sync user id',
            'xhr error',
            'Number of user syncs exceeded for',
            'Bid does not meet price floor',
            'Brightcom server returned empty/non-json response',
        ]

        this.ocmGoneMadPreview()
    }

    async isAdBlocked() {
        if (this.config.debug) {
            console.log('[OCM] Checking for Adblock...')
        }

        let url = ADS_BY_GOOGLE_URL;
        let options = {
            method: 'HEAD',
            mode: 'no-cors'
        };

        await fetch(url, options).then((response) => {
            if (this.config.debug) {
                console.log(LOG_PREFIX + ' adsbygoogle HEAD call response', response)
            }
            if (response.status === 0 || response.status === 200) {
                this.window.OCM.adBlocked = false
            }

            if (response.redirected) { // uBlock
                this.window.OCM.adBlocked = true
            }
        }).catch((error) => {
            this.window.OCM.adBlocked = true
        });
    }

    /**
     * Determines if current device is mobile
     * @returns boolean
     */
    isMobile() {
        return this.is_mobile;
    }

    getBrowser() {
        return this.browser;
    }

    hasJsonStructure(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }

        return true;
    }

    /**
     * Supplies the ocm configuration to the OCM Chrome extension
     * @param config
     */
    ocmChromeExt(config) {
        if (this.config.debug) {
            console.info('[OCM] Provide data to our Chrome extension')
        }
        let data = {type: "OCM_CONFIG", data: config};
        this.window.postMessage(data, "*");
    }

    /**
     * Creates a promise for geolocating the current user
     * @returns {Promise<unknown>}
     */
    geolocation() {
        if (this.config.debug) {
            console.info('[OCM] Geolocating...')
        }

        return new Promise(resolve => {
            if (this.window.OCM.geo) {
                resolve()
                return
            }

            axios.get(GEOLOCATION_WORKER_URL)
                .then((response) => {
                    this.window.OCM.geo = response.data
                    resolve()
                })
                .catch((error) => {
                    console.error(LOG_PREFIX, error);
                });
        });
    }

    contentLoaded() {
        if (this.config.debug) {
            console.info('[OCM] Checking for DOMContentLoaded...')
        }
        return new Promise(resolve => {
            const listener = () => {
                if (/^(?:loaded|interactive|complete)$/.test(document.readyState)) {
                    this.doc_body = this.doc_body || this.window.document.body || this.window.document.getElementsByTagName("body")[0];
                    if (this.doc_body) {
                        document.removeEventListener('readystatechange', listener);
                    }

                    resolve();
                }
            };

            document.addEventListener('readystatechange', listener);

            listener();
        })
    }

    isSlotInViewport(elementId) {
        let bounding
        let element = this.window.document.getElementById(elementId)
        let isSkyscraper = false
        let percentVisible = 0

        if (!element || typeof element === 'undefined') {
            return false
        }

        if (element.style.display === 'none') {
            element.style.display = 'block'
            bounding = element.getBoundingClientRect()
            element.style.display = 'none'
        } else {
            bounding = element.getBoundingClientRect()
        }

        isSkyscraper = (bounding.height >= 600)

        // Check if it's 100% in viewport
        if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (this.window.innerHeight || this.window.document.documentElement.clientHeight) &&
            bounding.right <= (this.window.innerWidth || this.window.document.documentElement.clientWidth)
        ) {
            return true
        }

        // Check if some of it is not visible (from top)
        if (
            bounding.top < 0 &&
            bounding.left >= 0
        ) {
            percentVisible = ((bounding.height - Math.abs(bounding.top)) / bounding.height) * 100
            if (isSkyscraper) {
                if (percentVisible >= 67) {
                    return true
                }
            } else {
                if (percentVisible >= 51) {
                    return true
                }
            }
        }

        // Check if some of it is not visible (from bottom)
        if (
            bounding.bottom >= (this.window.innerHeight || this.window.document.documentElement.clientHeight) &&
            bounding.left >= 0
        ) {
            percentVisible = ((bounding.height - (bounding.bottom - (this.window.innerHeight || this.window.documentElement.clientHeight))) / bounding.height) * 100
            if (isSkyscraper) {
                if (percentVisible >= 67) {
                    return true
                }
            } else {
                if (percentVisible >= 51) {
                    return true
                }
            }
        }

        return false
    }

    gptApiReady() {
        if (this.config.debug) {
            console.info('[OCM] Checking for googletag.apiReady...')
        }
        return new Promise((resolve, reject) => {
            let checkApiReady = (triesLeft) => {
                if (this.window.googletag && googletag.apiReady) {
                    resolve();
                } else {
                    if (!triesLeft) {
                        return reject('OCM gptApiReady: Max tries reached');
                    }

                    setTimeout(checkApiReady.bind(null, triesLeft - 1), 50);
                }
            }

            checkApiReady(40);
        })
    }

    gptPubAdsReady() {
        if (this.config.debug) {
            console.info(LOG_PREFIX + 'Checking for googletag.pubadsReady...')
        }
        return new Promise((resolve, reject) => {
            let checkPubadsReady = (triesLeft) => {
                if (this.window.googletag && this.window.googletag.pubadsReady) {
                    resolve();
                } else {
                    if (!triesLeft) {
                        return reject('OCM gptPubAdsReady: Max tries reached');
                    }

                    setTimeout(checkPubadsReady.bind(null, triesLeft - 1), 200);
                }
            }

            checkPubadsReady(50);
        })
    }

    throttle(callback, wait) {
        let time = Date.now();
        return function () {
            if ((time + wait - Date.now()) < 0) {
                callback();
                time = Date.now();
            }
        }
    }

    getServiceStates() {
        if (this.serviceStates && this.serviceStates.length) {
            return this.serviceStates
        }

        if (this.config.debug) {
            console.info('[OCM] Resolving service states...')
        }

        let servicesStates = {};

        Object.keys(this.config.services).forEach((service) => {
            servicesStates[service] = this.config.services[service].active;
        });

        return servicesStates;
    }

    // getDataAttributeValue(attribute) {
    //     let currentScript = document.currentScript
    //
    //     if (currentScript.hasAttribute('data-' + attribute)) {
    //         return currentScript.getAttribute('data-' + attribute);
    //     }
    //
    //     return null;
    // }

    allowPageType(page_types) {
        if (this.config.debug) {
            console.log(LOG_PREFIX + 'checking for page type validity', page_types, this.window.OCM.pageType)
        }
        return !!(page_types.includes(this.window.OCM.pageType) || page_types.includes('ROS'));
    }

    screenWidth() {
        if (this.config.debug) {
            console.info('[OCM] Resolving screen width...')
        }
        return this.window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
    }

    screenHeight() {
        if (this.config.debug) {
            console.info('[OCM] Resolving screen height...')
        }
        return this.window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;
    }

    /**
     * Creates a style node for custom styles needed for our services
     * @param style
     */
    loadStyle(style) {
        if (this.config.debug) {
            console.info('[OCM] Loading style...', style.substring(0, 100))
        }
        let style_el = document.createElement('style');
        style_el.appendChild(document.createTextNode(style));

        this.doc_head.appendChild(style_el);
    }

    /**
     * Creates an img node and appends to either "placement" or <head>
     * @param path
     * @param placement
     */
    loadImage(path, placement) {
        if (this.config.debug) {
            console.info('[OCM] Loading image...', path)
        }
        let img = document.createElement("img");

        img.src = path;

        if (placement === 'body') {
            this.doc_body.insertBefore(img, this.doc_body.firstChild);
        } else {
            this.doc_head.appendChild(img);
        }
    }

    loadScript(path, type, async, leclass, placement) {
        if (this.config.debug) {
            console.info('[OCM] Loading script...', path)
        }
        let script = document.createElement("script");
        script.type = type;
        script.src = path;

        if (async === 1) {
            script.async = "true";
        }

        if (leclass !== '') {
            script.class = leclass;
        }

        if (placement === 'body') {
            this.doc_body.insertBefore(script, this.doc_body.firstChild);
        } else {
            this.doc_head.appendChild(script);
        }
    }

    determineInjectionTarget(selector, position = 0, count_gt = 0, words = 0, words_gt = 0) {
        if (this.config.debug) {
            console.info(LOG_PREFIX + 'determineInjectionTarget', selector, position, count_gt, words, words_gt)
        }

        let node = null

        if (selector === '' || !selector) {
            return node
        }

        let elements

        if (typeof selector === 'string') {
            elements = this.window.document.querySelectorAll(selector);
        } else if (typeof selector === 'object') {
            elements = (selector.length === undefined) ? [selector] : selector
        }

        if (words === 0) {
            if (elements.length && elements.length >= count_gt) {
                if (position) {
                    if (position === -1) { // Position it at the last placement occurrence
                        node = elements[elements.length - 1]
                    } else {
                        node = elements[position]
                    }
                } else {
                    node = elements[0]
                }
            }
        } else {
            node = this.getParagraphAt(words, words_gt, elements)
        }

        return node
    }

    injectTag(target, element, place) {
        if (this.config.debug) {
            console.info(LOG_PREFIX + 'Injecting tag', target, element, place)
        }

        let result = null
        switch (place) {
            case 'insertInsideStart':
                result = target.insertAdjacentElement('afterbegin', element)
                break
            case 'insertInside':
                result = target.appendChild(element);
                break
            case 'insertAfter':
                result = target.parentNode.insertBefore(element, target.nextElementSibling);
                break
            case 'insertBefore':
                result = target.parentNode.insertBefore(element, target);
                break
            default:
                break
        }

        return result
    }

    calculateBidderTimeout() {
        let newTimeout = this.config.services.header_bidding.timeout
        //timeout to control how long bidders have to respond.
        if (this.config.debug || this.config.services.header_bidding.debug) {
            console.log('[OCM][Header Bidding] ' + 'bidderTimeout = ' + this.config.services.header_bidding.timeout);
        }

        // increase this.timeout by 100% if the device is mobile
        if (this.isMobile()) {
            newTimeout *= 2

            if (this.config.debug || this.config.services.header_bidding.debug) {
                console.log('[OCM][Header Bidding] ' + 'Mobile device, so bidderTimeout = ', newTimeout);
            }
        }

        return newTimeout
    }

    getParagraphAt(index, words_gt, elements) {
        let wordCount = 0;
        let tmpWordCount = 0;
        for (let element of elements) {
            wordCount = wordCount + element.innerHTML.split(/\s+/).length;
        }

        if (wordCount >= words_gt) {
            for (let element of elements) {
                tmpWordCount = tmpWordCount + element.innerHTML.split(/\s+/).length;
                if (tmpWordCount >= index) return element;
            }
        }

        return null
    }

    getParameterByName(name) {
        let url = this.window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
        let results = regex.exec(url);

        if (!results) return null;
        if (!results[2]) return '';

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    waitFor(variable, callback, max_tries = 50, failback) {
        const variables = variable.split('.')

        if (!variables.length) {
            if (typeof failback === 'function') {
                failback()
            }
        }

        let interval = setInterval(() => {
            max_tries--
            if (max_tries === 0) {
                clearInterval(interval);
                if (typeof failback === 'function') {
                    failback();
                }
            }

            let tmp = null
            variables.forEach((v) => {
                if (!tmp) {
                    tmp = this?.window[v]
                } else {
                    tmp = tmp[v]
                }
            })

            if (tmp) {
                clearInterval(interval);
                if (typeof callback === 'function') {
                    callback()
                }
            }
        }, 100);
    }

    getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    getUnique(arr, comp) {
        return arr
            .map(e => e[comp])
            .map((e, i, final) => final.indexOf(e) === i && i)  // store the keys of the unique objects
            .filter(e => arr[e]).map(e => arr[e]); // eliminate the dead keys & store unique objects
    }

    async parseRss(url) {
        return new Promise(async (resolve, reject) => {
            let end = (results) => {
                if (results.length) {
                    resolve(results)
                } else {
                    reject(results)
                }
            }

            await fetch(url)
                .then(response => response.text())
                .then(str => new this.window.DOMParser().parseFromString(str, "text/xml"))
                .then(feed => {
                    let results = []
                    let items = feed.querySelectorAll('item, entry')

                    let isAtom = false;

                    if (feed.querySelector('feed')?.getAttribute('xmlns')?.indexOf('Atom') > -1) {
                        isAtom = true;
                    }

                    Array.from(items).forEach((item) => {
                        if (this.config.debug) {
                            console.log(LOG_PREFIX, 'Fetched RSS item', item)
                        }
                        let title_el = item.querySelector('title')
                        let url_el = item.querySelector('link')
                        let image_el = item.querySelector('image')
                        let other_el = item.querySelector('[url][width][height]')
                        let enclosure_el = item.querySelector('enclosure')
                        let description_el = item.querySelector('description')
                        let media_content_el = item.getElementsByTagName('media:content').length > 0 ? item.getElementsByTagName('media:content').item(0) : null;
                        let media_thumbnail_el = item.getElementsByTagName('media:thumbnail').length > 0 ? item.getElementsByTagName('media:thumbnail').item(0) : null;

                        if (isAtom) {
                            title_el = item.querySelector('title')
                            image_el = item.querySelector('link[type="image/jpeg"], link[type="image/png"], link[type="image/jpg"]')?.getAttribute('href')
                            url_el = item.querySelector('link[rel="alternate"]')?.getAttribute('href')

                            results.push({
                                title: (title_el) ? title_el.innerHTML
                                    .replace("//<![CDATA[", "")
                                    .replace("//]]>", "")
                                    .replace("<![CDATA[", "")
                                    .replace("]]>", "") : '',
                                link: (url_el) ? url_el : '',
                                image: image_el
                            })

                            return;
                        }

                        if (!title_el || !url_el) {
                            if (this.config.debug) {
                                console.log(LOG_PREFIX, 'Failed to get title or url element', item)
                            }
                            end([])
                        }

                        let image_url = '';
                        if (image_el) {
                            image_url = image_el.innerHTML

                            if (image_el.querySelector('url')) {
                                image_url = image_el.querySelector('url').innerHTML
                            }

                            if (image_url.indexOf('<url>') > -1) {
                                image_url = image_url.replace('<url>', '').replace('</url>', '')
                            }
                        } else if (other_el && other_el.getAttribute('url').length) {
                            image_url = other_el.getAttribute('url')
                        } else if (enclosure_el && enclosure_el.getAttribute('url').length) {
                            image_url = enclosure_el.getAttribute('url')
                        } else if (media_content_el) {
                            image_url = media_content_el.getAttribute('url')
                        } else if (media_thumbnail_el) {
                            image_url = media_thumbnail_el.getAttribute('url')
                        } else if (description_el) {
                            image_url = this.extractImageFromDescription(item.innerHTML)
                        }

                        if (image_url.length) {
                            results.push({
                                title: (title_el) ? title_el.innerHTML
                                    .replace("//<![CDATA[", "")
                                    .replace("//]]>", "")
                                    .replace("<![CDATA[", "")
                                    .replace("]]>", "") : '',
                                link: (url_el) ? url_el.innerHTML
                                    .replace("//<![CDATA[", "")
                                    .replace("//]]>", "")
                                    .replace("<![CDATA[", "")
                                    .replace("]]>", "") : '',
                                image: image_url
                            })
                        }
                    })

                    // remove duplicates
                    results = this.getUnique(results, 'link')

                    // remove current url
                    results = results.filter(item => {
                        return (item && item.link && item.link !== this.window.location.href)
                    })

                    results = results.sort(() => .5 - Math.random()).slice(0, 10)

                    if (this.config.debug) {
                        console.log(LOG_PREFIX, 'RSS items: ', results)
                    }

                    end(results)
                });
        })
    }

    extractImageFromDescription(description) {
        const regex = /img[^>]+src=['"]([^">]+)['"]/gm

        const matches = description.matchAll(regex)
        for (const match of matches) {
            if (typeof match[1] !== 'undefined') {
                return match[1]
            }
        }

        return ''
    }

    clipHiddenOverflows(element, initEl) {
        const isHidden = (el) => {
            let overflow = this.window.getComputedStyle(el).overflow
            return overflow === "hidden"
        }

        let thisEl = element
        if (this.config.debug) {
            if (!initEl) console.log(LOG_PREFIX + '** Overflow check commence!', thisEl);
        }
        let origEl = initEl || thisEl
        if (isHidden(thisEl) && thisEl.tagName !== "BODY") {
            if (this.config.debug) {
                console.warn("Overflow found on:", thisEl.tagName, {
                    issue: "OVERFLOW IS HIDDEN",
                    tagName: thisEl.tagName,
                    id: thisEl.id,
                    class: thisEl.className,
                    element: thisEl
                })
            }

            let el_style = thisEl.getAttribute('style')
            thisEl.setAttribute('style', el_style + '; overflow:clip !important')
        }

        if (thisEl.parentElement) {
            return this.clipHiddenOverflows(thisEl.parentElement, origEl);
        } else {
            if (this.config.debug) {
                console.log(LOG_PREFIX + 'Overflow check complete! original element:', origEl)
            }
        }
    }

    calculateLoggingSamples() {
        let rand = Math.floor(Math.random() * 100);
        this.willSampleWarnings = (rand < 2)
        this.willSampleErrors = (rand < 10)
    }

    getRandomString(length = 7) {
        return (Math.random() + 1).toString(36).substring(length);
    }

    ocmGoneMadPreview() {
        let campaign_id = this.getParameterByName('campaign_id')
        let adformat = this.getParameterByName('adformat')

        if (campaign_id && campaign_id !== '' && adformat && adformat !== '') {
            // Prevent existing madinad setup from running
            // Find [[1,1]] sized HB adUnits with adform as their only bidder and remove them

            this.contentLoaded().then(() => {
                let script = document.createElement('script')
                script.id = 'ocm-gone-mad-sdk'
                script.src = `https://static.madinad.com/static/ocm-gone-mad.min.js?campaign_id=${campaign_id}&adformat=${adformat}&cb=${new Date().getTime()}`
                this.doc_body.append(script)
            })
        }
    }

    lazyLoad(selector, options, callback) {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.intersectionRatio >= 0) {
                    if (callback && typeof callback === 'function') {
                        callback(entry, observer);
                    }
                }
            });
        }, options);

        const element = this.window.document.querySelector(selector);
        if (element) {
            observer.observe(element);
        } else {
            if (this.config.debug) {
                console.log(LOG_PREFIX + 'Could not find defined selector: ' + selector);
            }
        }
    }

    //
    // errorCapture() {
    //     window.addEventListener('error', (event) => {
    //         console.log('inside errorCapture()', event.message, event.source, event.lineno, event.colno, event.error)
    //         try {
    //             if (event.source.includes('orangeclickmedia') || // any script loaded through OCM (prebid, prebid creative, outstream player etc.)
    //                 event.source.includes('teads') ||
    //                 event.source.includes('securepubads') || // googletag
    //                 event.source.includes('amazon-adsystem') || // amazon
    //                 event.source.includes('vi-serve') || // video intelligence
    //                 event.source.includes('engageya') ||
    //                 event.source.includes('quantcast') || // quantcast
    //                 event.source.includes('choice') || // quantcast
    //                 event.source.includes('sekindo') || // primis
    //                 event.source.includes('btloader')) { // blockthrough
    //                 axios.get(ERROR_CAPTURE_WORKER_URL +
    //                     '?hostname=' + window.location.hostname +
    //                     '&url=' + window.location.href +
    //                     '&page_type=' + window.OCM.pageType +
    //                     '&msg=' + encodeURIComponent(event.message) +
    //                     '&source=' + encodeURIComponent(event.source) +
    //                     '&line=' + event.lineno +
    //                     '&column=' + event.colno +
    //                     '&error=' + JSON.stringify(event.error))
    //             }
    //         } catch (error) {
    //             if (this.config.debug) {
    //                 console.log('Error caught within window.onerror', error)
    //             }
    //         }
    //     })
    // }
}
